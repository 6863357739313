import {
  validateAllUT,
  validateUT,
} from "../../components/distribution/validation/distrValidation";
import {
  autofixSingleHelper,
  validateSingleUT,
} from "../../components/distribution/validation/distrValidationHelpers";
import {
  EDIT_RELEASE_FIELD,
  POPULATE_RELEASE_FORM,
  ADD_RELEASE_PRIMARY_ARTIST_FIELD,
  ADD_RELEASE_CONTRIBUTOR_FIELD,
  REMOVE_RELEASE_PRIMARY_ARTIST_FIELD,
  REMOVE_RELEASE_CONTRIBUTOR_FIELD,
  POPULATE_TRACKS_FILES,
  POPULATE_TRACKS_FORM,
  POPULATE_USER_EMAIL,
  POPULATE_CURRENT_TRACK_FORM,
  EDIT_CURRENT_TRACK_FIELD,
  EDIT_CURRENT_TRACK,
  TOGGLE_EXPAND_TRACK_PANEL,
  CLOSE_TRACK_PANEL,
  CLOSE_OTHER_TRACK_PANELS,
  ADD_UPLOAD_TRACKS_PRIMARY_ARTIST_FIELD,
  ADD_UPLOAD_TRACKS_CONTRIBUTOR_FIELD,
  REMOVE_UPLOAD_TRACKS_PRIMARY_ARTIST_FIELD,
  REMOVE_UPLOAD_TRACKS_CONTRIBUTOR_FIELD,
  ADD_AND_PREPOPULATE_TRACK_WITH_BASIC_INFORMATION,
  VALIDATE_ALL_TRACKS,
  VALIDATE_UT_FIELD,
  CLEAN_UP_SUBMITTING,
  REMOVE_TRACK,
  AUTOFIX_SINGLE,
  UPDATE_FORM_WITH_TRACK_LICENSE_FILE,
  REMOVE_TRACK_LICENSE_FILE,
  SET_UPLOADING_IN_PROGRESS,
  TOGGLE_UT_LICENSE_CONFIRM_CHECKBOX,
  SET_USED_ARTIST_FIELDS,
  LOCK_FORM_FIELD,
  SET_USER_SALES,
  UPDATE_TRACK_TYPE,
} from "../actions/distributionActions";
import { cloneDeep } from "lodash";

var initialState = {
  releaseForm: {
    "#title": "",
    "#remix_or_version": "",
    "#participants": {
      primary: [{ primary: "" }],
      contributors: [{ type: "", value: "" }],
    },
    "#writercontributors": {
      contributors: [{ type: "", value: "" }],
    },
    "#secondarycontributors": {
      contributors: [{ type: "", value: "" }],
    },
    "#othercontributors": {
      contributors: [{ type: "", value: "" }],
    },
    "#engineeringcontributors": {
      contributors: [{ type: "", value: "" }],
    },
    "#instrumentalcontributors": {
      contributors: [{ type: "", value: "" }],
    },
    "#adminiscontributors": {
      contributors: [{ type: "", value: "" }],
    },
    "#vocalcontributors": {
      contributors: [{ type: "", value: "" }],
    },
    "#primary_genre": "",
    "#secondary_genre": "",
    "#language": "",
    "#explicit_lyrics": false,
    "#upc": "",
    "#catalog_number": "",
    "#label": "",
    "#digital_release": "",
    "#original_release": "",
    "#license_type": "",
    "#license_info": "",
    "#p_line": "",
    "#p_year": "",
    "#c_line": "",
    "#c_year": "",
    "#cover_url": "initial",
    socialMediaLink1: "",
    socialMediaLink2: "",
    socialMediaLink3: "",
    releaseId: "",
  },
  lockedFields: {},
  usedArtistFields: [],
  usedArtistSlots: [],
  tracksFiles: [],
  tracksForm: [],
  tracksValidation: [],
  userEmail: "",
  submitting: false,
  allTracksFieldsBoolean: false,
  allTracksGeneralShareAmountValidation: false,
  allTracksReleaseOnePrimaryArtistPresentValidation: undefined,
  singleValidation: undefined,
  licensesCheckbox: undefined,
  userSales: [],
  userSalesIsEmpty: false,
};

function distributionReducer(state = initialState, action) {
  const contributorTypes = ["#participants",
                            "#writercontributors",
                            "#secondarycontributors",
                            "#othercontributors",
                            "#engineeringcontributors",
                            "#instrumentalcontributors",
                            "#adminiscontributors",
                            "#vocalcontributors"];

  switch (action.type) {
    case ADD_RELEASE_PRIMARY_ARTIST_FIELD:
      state.releaseForm["#participants"].primary.push({ primary: "" });
      return state;
    case ADD_RELEASE_CONTRIBUTOR_FIELD:
     // console.log("Add release",action);
      state.releaseForm[action.databaseField].contributors.push({
          type: "",
          value: "",
        });
      return state;
    case REMOVE_RELEASE_PRIMARY_ARTIST_FIELD:
      state.releaseForm["#participants"].primary.splice(action.inputId, 1);
      return state;
    case REMOVE_RELEASE_CONTRIBUTOR_FIELD:
      //console.log("Remove release",action);
      state.releaseForm[action.databaseField].contributors.splice(action.inputId, 1);
      return state;
    case EDIT_RELEASE_FIELD:
      if (!state.releaseForm) return state;
      //console.log("Reducer state", state);
      if (contributorTypes.includes(action.databaseField)) {
        //console.log(action);
        let editedParticipants =
          state.releaseForm[action.databaseField][action.subField];
        editedParticipants[action.inputId] = {
          ...editedParticipants[action.inputId],
          [action.databaseKey]: action.value,
        };
        state.releaseForm[action.databaseField][action.subField] =
          editedParticipants;
      }
      else {
        state.releaseForm[action.databaseField] = action.value;
      }
      return state;
    case POPULATE_RELEASE_FORM:
      state.releaseForm = action.releaseForm;
      return state;
    case POPULATE_TRACKS_FILES:
      state.tracksFiles = action.tracksFiles;
      return state;
    case POPULATE_TRACKS_FORM:
      state.tracksForm = action.tracksForm;
      return state;
    case UPDATE_TRACK_TYPE:
      const { index, value } = action.payload;

      const updatedTrackForm = state.tracksForm.map((track, idx) => {
        if (idx === index) {
          let updatedLanguage = track.form["#language"];
          let originalLanguage = "";
        if (value === "instrumental") {
            originalLanguage = updatedLanguage;
            updatedLanguage = "zxx";
          } else if (updatedLanguage === "zxx") {
            updatedLanguage = originalLanguage;
          }

          const updatedForm = { ...track.form, "#language": updatedLanguage };
          return { ...track, trackType: value, form: updatedForm };
        }
        return track;
      });

      return {
        ...state,
        tracksForm: updatedTrackForm,
      };

    case POPULATE_CURRENT_TRACK_FORM:
      state.tracksForm[action.trackIndex] = state.currentTrackForm;
      return state;
    case EDIT_CURRENT_TRACK_FIELD:
      if (!state.tracksForm) return state;
      if (contributorTypes.includes(action.databaseField)) {
         state.tracksForm[action.trackIndex].form[action.databaseField][
          action.databaseKey
        ][action.inputId][action.subKey] = action.value;
      }
      else {
        state.tracksForm[action.trackIndex].form[action.databaseField] =
          action.value;
      }
      return state;
    case EDIT_CURRENT_TRACK:
      if (!state.tracksForm) return state;

      state.tracksForm[action.trackIndex] = {
        ...state.tracksForm[action.trackIndex],
        ...action.value,
      };

      return state;
    case ADD_UPLOAD_TRACKS_PRIMARY_ARTIST_FIELD:
      state.tracksForm[action.trackIndex].form["#participants"].primary.push({
        type: "primary",
        name: "",
        email: "",
        share: "",
      });
      return state;
    case TOGGLE_EXPAND_TRACK_PANEL:
      state.tracksForm[action.trackIndex].isExpanded =
        !state.tracksForm[action.trackIndex].isExpanded;
      return state;
    case CLOSE_TRACK_PANEL:
      state.tracksForm[action.trackIndex].isExpanded = false;
      return state;
    case CLOSE_OTHER_TRACK_PANELS:
      state.tracksForm = state.tracksForm.map((track) => {
        track.isExpanded = false;
        return track;
      });

      state.tracksForm[action.trackIndex].isExpanded = true;
      return state;
    case ADD_UPLOAD_TRACKS_CONTRIBUTOR_FIELD:
      state.tracksForm[action.trackIndex].form[
        "#participants"
      ].contributors.push({
        type: "",
        name: "",
        email: "",
        share: "",
      });
      return state;
    case REMOVE_UPLOAD_TRACKS_PRIMARY_ARTIST_FIELD:
      state.tracksForm[action.trackIndex].form["#participants"].primary.splice(
        action.inputId,
        1
      );
      return state;
    case REMOVE_UPLOAD_TRACKS_CONTRIBUTOR_FIELD:
      state.tracksForm[action.trackIndex].form[
        "#participants"
      ].contributors.splice(action.inputId, 1);
      return state;
    case ADD_AND_PREPOPULATE_TRACK_WITH_BASIC_INFORMATION:
      let newState = cloneDeep(state);
      newState.tracksForm.push(getNewTrackForm(state, action));
      return newState;
    case UPDATE_FORM_WITH_TRACK_LICENSE_FILE:
      state = cloneDeep(state);
      state.tracksForm[action.trackIndex].licenseFiles[action.inputIndex] = {
        url: action.licenseFileUrl,
        originalFileName: action.originalFileName,
      };
      return state;
    case REMOVE_TRACK_LICENSE_FILE:
      state = cloneDeep(state);
      state.tracksForm[action.trackIndex].licenseFiles[action.inputIndex] = {};
      return state;
    case REMOVE_TRACK:
      let newTracks = cloneDeep(state.tracksForm);
      newTracks.splice(action.trackIndex, 1);
      state.tracksForm = newTracks;

      let newTracksFiles = cloneDeep(state.tracksFiles);
      newTracksFiles.splice(action.trackIndex, 1);
      state.tracksFiles = newTracksFiles;

      let newVal = cloneDeep(state.tracksValidation);
      newVal.splice(action.trackIndex, 1);
      state.tracksValidation = newVal;

      // updating general validation state for UI display
      state.allTracksFieldsBoolean = newVal.some(
        (track) => track.__allFieldsBoolean
      );
      state.allTracksGeneralShareAmountValidation = newVal.some(
        (track) => track.__generalShareAmountValidation
      );
      state.singleValidation = undefined;
      return state;
    case SET_UPLOADING_IN_PROGRESS:
      state.uploadingInProgress = action.value;
      return state;
    case VALIDATE_ALL_TRACKS:
      // if (state.tracksForm.length === 1) {
      //   state.singleValidation = validateSingleUT(
      //     state.tracksForm[0].form,
      //     state.releaseForm
      //   );
      // } else state.singleValidation = undefined;

      state.tracksValidation = validateAllUT(
        state.tracksForm,
        state.releaseForm
      );
      state.allTracksFieldsBoolean = state.tracksValidation.some(
        (track) => track.__allFieldsBoolean
      );
      state.allTracksGeneralShareAmountValidation = state.tracksValidation.some(
        (track) => track.__generalShareAmountValidation
      );
      state.allTracksReleaseOnePrimaryArtistPresentValidation =
        state.tracksValidation.some(
          (track) => track.__releaseOnePrimaryArtistPresentValidation
        );

      // checkbox for licenses (if false, validation will appear)
      if (state.licensesCheckbox === undefined) state.licensesCheckbox = false;

      state.submitting = true;
      return state;
    case TOGGLE_UT_LICENSE_CONFIRM_CHECKBOX:
      if (state.licensesCheckbox === undefined) state.licensesCheckbox = true;
      else state.licensesCheckbox = !state.licensesCheckbox;
      return state;
    case AUTOFIX_SINGLE:
      state.tracksForm[0].form = autofixSingleHelper(
        state.tracksForm[0].form,
        state.releaseForm,
        state.userEmail
      );

      // Check our own fix lol
      state.singleValidation = validateSingleUT(
        state.tracksForm[0].form,
        state.releaseForm
      );
      return state;
    case CLEAN_UP_SUBMITTING:
      state.submitting = false;
      return state;
    case VALIDATE_UT_FIELD:
      let val = validateUT(
        action.value,
        action.databaseField,
        action.databaseKey,
        action.databaseSubKey,
        action.inputId,
        state.tracksForm[action.trackIndex].form,
        state.tracksForm[action.trackIndex]
      );
      if (action.databaseField !== "#participants") {
        state.tracksValidation[action.trackIndex] = {
          ...state.tracksValidation[action.trackIndex],
          [action.databaseField]: val,
        };
      } 
      else if (action.databaseField !== "#writercontributors") {
        state.tracksValidation[action.trackIndex] = {
          ...state.tracksValidation[action.trackIndex],
          [action.databaseField]: val,
        };
      }
      else if (action.databaseField !== "#secondarycontributors") {
        state.tracksValidation[action.trackIndex] = {
          ...state.tracksValidation[action.trackIndex],
          [action.databaseField]: val,
        };
      }
      else if (action.databaseField !== "#othercontributors") {
        state.tracksValidation[action.trackIndex] = {
          ...state.tracksValidation[action.trackIndex],
          [action.databaseField]: val,
        };
      }
      else if (action.databaseField !== "#engineeringcontributors") {
        state.tracksValidation[action.trackIndex] = {
          ...state.tracksValidation[action.trackIndex],
          [action.databaseField]: val,
        };
      }
      else if (action.databaseField !== "#instrumentalcontributors") {
        state.tracksValidation[action.trackIndex] = {
          ...state.tracksValidation[action.trackIndex],
          [action.databaseField]: val,
        };
      }
      else if (action.databaseField !== "#adminiscontributors") {
        state.tracksValidation[action.trackIndex] = {
          ...state.tracksValidation[action.trackIndex],
          [action.databaseField]: val,
        };
      }
      else if (action.databaseField !== "#vocalcontributors") {
        state.tracksValidation[action.trackIndex] = {
          ...state.tracksValidation[action.trackIndex],
          [action.databaseField]: val,
        };
      }
      else {
        // TODO: rewrite this weird $h1t (works though :P)
        state.tracksValidation[action.trackIndex] = {
          ...state.tracksValidation[action.trackIndex],
          [action.databaseField]: {
            ...state.tracksValidation[action.trackIndex]?.[
              action.databaseField
            ],
            [action.databaseKey]: {
              ...state.tracksValidation?.[action.trackIndex]?.[
                action.databaseField
              ]?.[action.databaseKey],
              [action.inputId]: {
                ...state.tracksValidation[action.trackIndex]?.[
                  action.databaseField
                ]?.[action.databaseKey]?.[action.inputId],
                [action.databaseSubKey]: val,
              },
            },
          },
        }; // rewrite?
      }
      return state;
    case POPULATE_USER_EMAIL:
      state.userEmail = action.userEmail;
      return state;
    case LOCK_FORM_FIELD:
      state.lockedFields = {
        ...state.lockedFields,
        [action.lockedField.databaseField]: action.lockedField,
      };
      return state;
    case SET_USED_ARTIST_FIELDS:
      const mappedUsedArtists = action.fields.map((artist, index) => {
        return { value: artist.primary, label: artist.primary, index: index };
      });

      state.usedArtistFields = mappedUsedArtists;
      state.usedArtistSlots = action.slots;

      return state;
    case SET_USER_SALES:
      let data = action.data.map((doc) => {
        doc.start_date = new Date(doc.start_date);
        doc.end_date = new Date(doc.end_date);
        return doc;
      });
      state.userSales = data;
      if (!data.length) state.userSalesIsEmpty = true;
      return state;
    default:
      return state;
  }
}

const getNewTrackForm = (state, action) => {
  return {
    originalFileName: action.originalFileName,
    isExpanded: false,
    "#audio_url": action.wavFileLink,
    licenseFiles: [{}, {}, {}],
    form: {
      "#track_title": action.originalFileName,
      "#remix_or_version": state.releaseForm["#remix_or_version"],
      // convert participants from release info structure
      "#participants": {
        primary: state.releaseForm["#participants"].primary.map((primary) => ({
          type: "primary",
          name: primary.primary,
          email: "",
          share: "",
        })),
        contributors: state.releaseForm["#participants"].contributors.map(
          (contributor) => ({
            type: contributor.type,
            name: contributor.value
          })
        ),
      },
      "#writercontributors": {
        contributors: state.releaseForm["#writercontributors"].contributors.map(
          (contributor) => ({
            type: contributor.type,
            name: contributor.value
          })
        ),
      },
      "#secondarycontributors": {
        contributors: state.releaseForm["#secondarycontributors"].contributors.map(
          (contributor) => ({
            type: contributor.type,
            name: contributor.value
          })
        ),
      },
      "#othercontributors": {
        contributors: state.releaseForm["#othercontributors"].contributors.map(
          (contributor) => ({
            type: contributor.type,
            name: contributor.value
          })
        ),
      },
      "#engineeringcontributors": {
        contributors: state.releaseForm["#engineeringcontributors"].contributors.map(
          (contributor) => ({
            type: contributor.type,
            name: contributor.value
          })
        ),
      },
      "#instrumentalcontributors": {
        contributors: state.releaseForm["#instrumentalcontributors"].contributors.map(
          (contributor) => ({
            type: contributor.type,
            name: contributor.value
          })
        ),
      },
      "#adminiscontributors": {
        contributors: state.releaseForm["#adminiscontributors"].contributors.map(
          (contributor) => ({
            type: contributor.type,
            name: contributor.value
          })
        ),
      },
      "#vocalcontributors": {
        contributors: state.releaseForm["#vocalcontributors"].contributors.map(
          (contributor) => ({
            type: contributor.type,
            name: contributor.value
          })
        ),
      },
      "#primary_genre": state.releaseForm["#primary_genre"],
      "#secondary_genre": state.releaseForm["#secondary_genre"],
      "#language": state.releaseForm["#language"],
      "#explicit_lyrics": state.releaseForm["#explicit_lyrics"],
      "#p_line": state.releaseForm["#p_line"],
      "#p_year": state.releaseForm["#p_year"],
      "#isrc": "",
      "#iswc": "",
      _lyrics: "",
    },
  };
};

export default distributionReducer;
